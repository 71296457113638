<template>
  <div class="container">
    <div class="body d-md-flex align-items-center justify-content-between">
      <div class="box-1 mt-md-0 mt-5">
        <img
          src="https://images.pexels.com/photos/2033997/pexels-photo-2033997.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
          class=""
          alt=""
        />
      </div>
      <div class="box-2 d-flex flex-column h-100">
        <div class="mt-5">
          <p class="mb-1 h-1">Iniciar Sessão</p>
          <p class="text-muted mb-2">
            Backoffice do website
            <a href="https://www.idivia.com/en/">idivia.com</a>
          </p>
          <div class="d-flex flex-column">
            <div class="mt-5">
              <form v-on:submit.prevent="login()">
                <div class="form-group mb-3">
                  <input
                    id="inputEmail"
                    type="email"
                    placeholder="Email"
                    required
                    autofocus=""
                    v-model="email"
                    class="form-control"
                  />
                </div>
                <div class="form-group mb-3">
                  <input
                    id="inputPassword"
                    type="password"
                    placeholder="Password"
                    required
                    v-model="password"
                    class="form-control"
                  />
                </div>
                <button id="entrar" type="submit" class="btn btn-primary float-right text-uppercase mb-2">
                  Entrar
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="mt-auto">
          <p class="footer text-muted mb-0 mt-md-0 mt-4">Caso não tenha conta fale com o administrador</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
router.push('/');
import router from '../router';
import { mapGetters } from 'vuex';
export default {
  data: function () {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    /* FUNÇÃO PARA FAZER LOGIN */
    async login() {
      console.log('FUNÇÃO LOGIN');
      try {
        console.log(this.email, this.password);
        await this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
        });

        const first = JSON.parse(localStorage.getItem('user')).first;
        console.log(first);
        if (first) {
          router.push('/profile');
        } else {
          router.push('/');
        }
        /* SWEET-ALERT */
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Bem-Vindo',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        /* SWEET-ALERT */
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: err,
        });
      }
    },
  },
  computed: {
    ...mapGetters(['getLoginMessage']),
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  margin: 50px auto;
}

.body {
  position: relative;
  width: 720px;
  height: 440px;
  margin: 20px auto;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.box-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box-2 {
  padding: 10px;
}

.box-1,
.box-2 {
  width: 50%;
}

.h-1 {
  font-size: 24px;
  font-weight: 700;
}

.text-muted {
  font-size: 14px;
}

.container .box {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  text-decoration: none;
  color: #615f5fdd;
}

.box:active,
.box:visited {
  border: 2px solid rgb(255, 147, 147);
}

.box:hover {
  border: 2px solid rgb(255, 147, 147);
}

.btn.btn-primary {
  margin-left: 75%;
  background-color: rgba(255, 147, 147, 0);
  color: rgb(255, 147, 147);
  border: 0px;
  padding: 3px;
}

.btn.btn-primary .fas.fa-chevron-right {
  font-size: 12px;
}

.btn:hover {
  background-color: rgb(255, 147, 147);
  color: white;
}
.footer .p-color {
  color: rgb(255, 147, 147);
}

.footer.text-muted {
  font-size: 10px;
}

.fas.fa-times {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
  background-color: #f3cff379;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-control {
  border: 0px solid black;
  border-bottom: 2px solid grey;
  border-radius: 0px;
}
.form-control:focus {
  color: rgb(255, 147, 147);
  box-shadow: none;
  border-color: rgb(255, 147, 147);
}
input {
  margin-bottom: 10px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  body {
    padding: 10px;
  }

  .body {
    width: 100%;
    height: 100%;
  }

  .box-1 {
    width: 100%;
  }

  .box-2 {
    width: 100%;
    height: 440px;
  }
}
</style>
=
