<template>
  <div class="box">
    <LoginForm />
  </div>
</template>
<script>
import LoginForm from "@/components/LoginForm.vue";
export default {
  components: {
    LoginForm,
  },
};
</script>
<style scoped>
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}
</style>
